body {
    color: #fff;
    background: radial-gradient(circle closest-corner at 50% 60px, #113, #111);

    margin: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1 {
    text-align: center;
    font-family: 'Dancing Script', cursive;
    font-size: 72px;
}

h2 {
    color: #fffb;
    display: flex;
    margin: 40px 0;
    font-weight: bold;
    font-size: 1.5em;
}
h2:after,
h2:before {
    content: '';
    flex: 1;
    background: linear-gradient(to bottom, #fff0 48.5%, #555 48.5%, #555 51.5%, #fff0 51.5%);
}

h2:after {
    margin-left: 20px;
}

h2:before {
    margin-right: 20px;
}

p {
    max-width: 50em;
    margin: 0.8em auto;
    color: #fffc;
    text-align: justify;
}

p.signature {
    text-align: center;
    font-family: 'Dancing Script', cursive;
    font-size: 1.5rem;
}


div.gallery-container {
    display: block;
    min-height: 1px;
    width: 100%;
    overflow: auto;
    text-align: center;
    margin: 40px 0;
}

div.album-caption {
    display: block;
    width: 100%;
    position: absolute;
    size: 1.5em;
    padding: 3px 0 6px 0;
    top: 0;
    left: 0;
    background-color: #000a;
}
